import axios from 'axios';
//samsung
export default class PasswordService {
    async changePassword(user_id) {
        return await axios.patch(`/admin/user/users/${user_id }`,{
            
            new_password: this.new_pass

        }).then((res) => res)
    }

    async editInfos(id, name) {

        return await axios.patch(`/admin/user/users/${id, name}`)

    }


    async resetPassword(newp, email, code) {
        return await axios.post(`/admin/user/reset-password`, {

            password: newp,
            email: email,
            code: code,
        }).then((res) => res)
    }

}
