<template>
    <Toast />
    <div class="login-body">
        <div class="login-wrapper">
            <div class="login-panel">
                <a @click="goDashboard" class="logo">
                    <img src="/assets/layout/images/Logohanatour.png" alt="samsung" style="margin-bottom: 20px; height: 40px" />
                    <h3 style="color: #002; line-height: 0.5em">관리자 로그인</h3>
                    <p style="font-size: 14px; color: rgba(0, 0, 0, 0.6)">계정에 로그인해주세요.</p>
                </a>

                <InputText @keyup.enter="Login" type="text" placeholder="이메일을 입력하세요." v-model="login_user" id="email" :class="`${error.login_user ? 'p-invalidusername' : ''}`" />
                <!-- <InputText @keyup.enter="Login" id="email" placeholder="Email" v-model="login_user" :class="`${error.login_user ? 'p-invalid' : ''}`" /> -->
                <div class="text-redx">{{ error.login_user }}</div>
                <div class="text-redx">{{ email_err }}</div>

                <Password @keyup.enter="Login" id="password" placeholder="비밀번호를 입력하세요." :feedback="newLocal" v-model="login_password" :class="`${error.login_password ? 'p-invalid' : ''}`" toggle-mask />
                <div class="text-redx">{{ error.login_password }}</div>
                <div class="text-redx">{{ pass_err }}</div>
                <div class="p-d-flex p-jc-between p-align-center">
                    <div class="field-checkbox" style="text-align: left; display: flex; max-width: 250px; font-size: 10px; padding-bottom: 10px">
                        <Checkbox id="rem" :binary="true" v-model="remember" @change="rememberMe()"></Checkbox>
                        <label for="rem" style="padding-left: 5px; font-size: 14px; color: #181818">아이디 저장</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                    <Button label="회원가입" type="button" @click="$router.push('/sign-up')" class="p-button-text p-button-primary"></Button>
                </div>
                <Button label="로그인" type="button" @click="Login" class="colorchange"></Button>
                <!-- <a href="#" @click="open">{{ $t('Forget Password') }}</a> -->
                <!-- <a href="#">forget dfc password?</a> -->
                <!-- <p>Don’t you have an account, <a href="#">sign up</a></p> -->
            </div>
        </div>
        <Dialog v-model:visible="display" :breakpoints="{ '960px': '75vw' }" :style="{ width: '30vw' }" :modal="true">
            <div class="p-field p-fluid">
                <p>5회 연속 로그인 시도 실패로 인해 계정이 일시적으로 차단되었습니다. 다시 로그인할 때 사용할 임시 비밀번호는 등록한 메일 주소 통해 확인 가능 하십니다.</p>
            </div>
            <template #footer>
                <Button label="Ok" @click="resetpass" icon="pi pi-check" class="p-button-primary" />
                <!-- <Button label="Dismiss" @click="close" icon="pi pi-pi-times" class="p-button-danger" /> -->
            </template>
        </Dialog>
    </div>
</template>

<script>
  import validateLogin from '../validations/validateLogin';
  import validateReset from '../validations/validateReset';
  import Swal from 'sweetalert2';
  import axios from 'axios';
  export default {
      data() {
          return {
              errmsg: '',
              helptxt: true,
              resetemail: '',
              display: false,
              email_err: '',
              pass_err: '',
              login_user: '',
              login_password: '',
              error: {},
              userid: '',
              errors: {},
              remember: true,
              login_failure_count: '',
              logincount: 0,
          };
      },
      mounted() {
          this.remember = localStorage.getItem('save') == 'true' ? true : false;
          this.userid = localStorage.getItem('Useridblockeruser');

          if (localStorage.getItem('save') == 'true') {
              this.login_user = localStorage.getItem('Uid');
              this.login_password = localStorage.getItem('Upass');
          }
      },
      methods: {
          rememberMe() {
              localStorage.setItem('save', this.remember);
              if (this.remember == true) {
                  localStorage.setItem('Uid', this.login_user);
                  localStorage.setItem('Upass', this.login_password);
              } else {
                  localStorage.removeItem('Uid');
                  localStorage.removeItem('Upass');
              }
          },
          open() {
              this.display = true;
          },
          close() {
              this.display = false;
          },

          async Login() {
              this.logincount++;
              sessionStorage.setItem('expiry_time', this.logincount);

              let credentials = {
                  login_user: this.login_user,
                  login_password: this.login_password,
              };
              const { isInvalid, error } = validateLogin(credentials);
              if (isInvalid) {
                  this.error = error;
                  console.log(error);
              } else {
                  var self = this;
                  this.error = {};
                  // console.log('pass');
                  await axios({
                      method: 'post',
                      url: 'admin/user/login',
                      data: credentials,
                  })
                      .then(function (response) {
                          console.log(response.data);
                          localStorage.setItem('token', response.data.access_token);
                          localStorage.setItem('Userid', response.data.user_id);
                          localStorage.setItem('Username', response.data.name);

                          window.location = '/';
                      })
                      .catch(function (err) {
                          console.log(err.response.data.message);
                          if (sessionStorage.getItem('expiry_time') > 4) {
                              Swal.fire({
                                  allowOutsideClick: false,
                                  text: '5회 연속 로그인 시도 실패로 인해 계정이 일시적으로 차단되었습니다. 다시 로그인할 때 사용할 임시 비밀번호는 등록한 메일 주소 통해 확인 가능 하십니다.',
                                  confirmButtonText: '확인',
                              }).then((result) => {
                                  if (result.isConfirmed) {
                                      // }).then(() => {
                                      //     setTimeout(() => {
                                      //         self.$toast.add({ severity: 'info', summary: '확인.', detail: '이메일 아이디를 확인해주세요', life: 3000 });
                                      //     }, 1000);
                                      // });
                                  }
                              });

                              self.logincount = 0;
                              sessionStorage.setItem('expiry_time', 0);

                              return axios.post(`admin/user/blocked-user/`, {
                                  login_user: self.login_user,
                              });
                              // sessionStorage.clear();
                          } else if (err.response.data.message) {
                              self.$toast.add({ severity: '오류', summary: err.response.data.message, life: 3000 });
                          } else {
                              self.$toast.add({ severity: '오류', summary: '이메일 또는 비밀번호가 잘못되었습니다.', life: 3000 });
                          }
                      });
                  // .catch(function (err) {
                  //     let text = err.response.data.message;
                  //     let result = text.match('Password');
                  //     let result1 = text.match('Email Does Not Exists');
                  //     console.log(text);
                  //     if (result == 'Password') {
                  //         self.pass_err = 'invalid password.';
                  //         self.email_err = '';
                  //     } else if (result1 == 'Email Does Not Exists') {
                  //         self.pass_err = '';
                  //         self.email_err = 'invalid email.';
                  //     } else {
                  //         // alert('Email or, password is incorrect');
                  //         self.$toast.add({ severity: '오류', summary: '이메일 또는 비밀번호가 잘못되었습니다.', life: 3000 });
                  //     }
                  // });
              }
          },
          resetpass() {
              let vcheckData = {
                  resetemail: this.resetemail,
              };
              const { isInvalid, error } = validateReset(vcheckData);
              if (isInvalid) {
                  this.error = error;
                  this.helptxt = false;
                  // console.log(error);
              } else {
                  this.errmsg = '';
                  var self = this;
                  this.helptxt = true;
                  this.error = {};
                  axios({
                      method: 'post',
                      url: '/admin/dfc/resetPassword',
                      data: { email: this.resetemail },
                  })
                      .then(function () {
                          self.close();
                          self.$toast.add({ severity: 'success', summary: '비밀번호가 성공적으로 변경되었습니다', detail: ` 새로운 비밀번호가 ${self.resetemail}로 발송되었습니다  `, life: 5000 });
                      })
                      .catch((err) => {
                          this.helptxt = false;
                          this.errmsg = err.response.data.message;
                      });
              }
          },
          goDashboard() {
              window.location = '/';
          },
      },
  };
</script>
<style scoped>
  .colorchange:focus {
      background-color: #fff;
      color: blue;
  }

  .p-password .pi-eye {
      top: 42%;
  }

  .p-invalidusername {
      border: 1px solid red !important;
  }
</style>
